import styled from 'styled-components';

import { ThemeProp } from '~/theme/Themes';

export const FilterButton = styled.button`
  font-size: ${(props: ThemeProp) => props.theme.fontSize.s};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.s};
  color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  stroke: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.white};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.neutrals.c200};
  position: relative;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  color: isDisabled ? props.theme.colors.neutrals.c400;

  &:disabled {
    background-color: ${(props: ThemeProp) => props.theme.colors.neutrals.c050};
    color: ${(props: ThemeProp) => props.theme.colors.neutrals.c400};
  }
`;
